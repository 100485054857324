import Container from '@components/container';
import { ContainerSize, RichText } from '@lib/types';

export default function RichTextSection({
  data: { title, html },
}: {
  data: RichText;
}) {
  return (
    <Container size={ContainerSize.wide}>
      <div className="grid grid-cols-12 gap-1.5 md:gap-6">
        {title && (
          <h1 className="text-32 md:text-35 leading-130 font-bold text-blue-800 col-start-2 col-span-11 lg:col-start-4 lg:col-span-6">
            {title}
          </h1>
        )}
        <div
          className="col-start-2 col-span-10 lg:col-start-4 lg:col-span-6 prose"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Container>
  );
}
